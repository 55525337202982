import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//styles
import "./styles/App.css";

//import Global contexts
import MusicContextProvider from "./contexts/MusicContext";
import ThemeContextProvider from "./contexts/ThemeContext";
import ScrollRigContextProvider from "./contexts/ScrollRigContext";
import NavigationContextProvider from "./contexts/NavigationContext";
import FullscreenContextProvider from "./contexts/FullscreenContext";

//components
import Preloader from "./components/Preloader";
import ScrollRig from "./components/ScrollRig";

//lazy Components / Routes - views import
const Header = lazy(() => import("./views/layout/Header"));
const Navigation = lazy(() => import("./views/layout/Navigation"));
const Footer = lazy(() => import("./views/layout/Footer"));
const MusicPlayer = lazy(() => import("./components/MusicPlayer"));
//views
const HomePage = lazy(() => import("./views/Home"));
const About = lazy(() => import("./views/About"));
const Work = lazy(() => import("./views/Work"));
const WorkDetails = lazy(() => import("./views/WorkDetails"));
const Contact = lazy(() => import("./views/Contact"));
const ErrorPage = lazy(() => import("./views/ErrorPage"));

//require imagesLoaded node module
//const imagesLoaded = require("imagesloaded");

//app functional component
const App = () => {
  //render
  return (
    <Suspense fallback={<Preloader status="active" />}>
      <ThemeContextProvider>
        <FullscreenContextProvider>
          <MusicContextProvider>
            <ScrollRigContextProvider>
              <NavigationContextProvider>
                <Router>
                  <main className="App">
                    <Header />
                    <Navigation />
                    {/* scrollRIG */}
                    <ScrollRig>
                      {/* scrollRIG */}
                      <main className="main">
                        <main className="main-body">
                          <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/About" element={<About />} />
                            <Route path="/Contact" element={<Contact />} />
                            <Route path="/work" element={<Work />} />
                            <Route path="/work/:id" element={<WorkDetails />} />
                            <Route element={<ErrorPage />} />
                          </Routes>
                        </main>
                        <Footer />
                        <MusicPlayer />
                      </main>
                      {/* scrollRIG */}
                    </ScrollRig>
                    {/* scrollRIG */}
                  </main>
                </Router>
              </NavigationContextProvider>
            </ScrollRigContextProvider>
          </MusicContextProvider>
        </FullscreenContextProvider>
      </ThemeContextProvider>
    </Suspense>
  );
};
/* }; */

export default App;
