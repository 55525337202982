import React, { createContext, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

//
export const ThemeContext = createContext();

//
const ThemeContextProvider = (props) => {
  //
  const [darkMode, setDarkMode] = useLocalStorage("dark-mode", false);

  //
  useEffect(() => {
    darkMode
      ? document.documentElement.setAttribute("data-theme", "dark")
      : document.documentElement.setAttribute("data-theme", "light");
    return () => {};
  }, [darkMode]);

  //update the global state method / function
  const switchTheme = () => {
    //
    setDarkMode((prevState) => !prevState);
  };

  //
  return (
    //pass the global state and the toggle function
    <ThemeContext.Provider
      value={{
        darkMode,
        switchTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
