import { useState, useEffect } from "react";

//useBrowserSize hook
export default function useWindowSize() {
  //
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  //
  useEffect(() => {
    const handleWindowResize = () => {
      //
      if (window.innerWidth) {
        setWindowWidth(window.innerWidth);
      } else if (
        document.documentElement &&
        document.documentElement.clientWidth !== 0
      ) {
        setWindowWidth(document.documentElement.clientWidth);
      } else if (document.body) {
        setWindowWidth(document.body.clientWidth);
      }

      //
      if (window.innerHeight) {
        setWindowHeight(window.innerHeight);
      } else if (
        document.documentElement &&
        document.documentElement.clientHeight !== 0
      ) {
        setWindowHeight(document.documentElement.clientHeight);
      } else if (document.body) {
        setWindowHeight(document.body.clientHeight);
      }
    };
    //
    window.addEventListener("resize", handleWindowResize);
    //clean up
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  //
  return { windowWidth, windowHeight };
}

//get width hook
export const getBrowserWidth = () => {
  if (window.innerWidth) {
    return window.innerWidth;
  } else if (
    document.documentElement &&
    document.documentElement.clientWidth !== 0
  ) {
    return document.documentElement.clientWidth;
  } else if (document.body) {
    return document.body.clientWidth;
  }
  return 0;
};

//get height hook
export const getBrowserHeight = () => {
  if (window.innerHeight) {
    return window.innerHeight;
  } else if (
    document.documentElement &&
    document.documentElement.clientHeight !== 0
  ) {
    return document.documentElement.clientHeight;
  } else if (document.body) {
    return document.body.clientHeight;
  }
  return 0;
};

//detect mobile hook
export const detectMobileDevice = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
