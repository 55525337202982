/* smoothscrollbar */
//import Scrollbar from "react-smooth-scrollbar";
import SmoothScrollbar, { ScrollbarPlugin } from "smooth-scrollbar";
//import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

//used to disable plugin
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = "modal";
  static defaultOptions = {
    open: false,
  };

  transformDelta(delta) {
    //console.log("scroll lock");
    return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

/* use mousewheel for x plugin */
/* class HorizontalScrollPlugin extends ScrollbarPlugin {
  static pluginName = "horizontalScroll";

  transformDelta(delta, fromEvent) {
    if (!/wheel/.test(fromEvent.type)) {
      return delta;
    }

    // @see: https://github.com/idiotWu/smooth-scrollbar/issues/181

    const { x, y } = delta;

    return {
      y: 0,
      x: Math.abs(x) > Math.abs(y) ? x : y,
      // x: Math.sign(x || y) * Math.sqrt(x*x + y*y),
    };
  }
} */

//main scrollbar class
class customScrollbar {
  //
  constructor(elm, cb, offset) {
    this.scrollbar = {};
    this.targetElement = elm;
    this.init(cb, offset);
  }

  //Static methods are defined on the class itself, and not on the prototype.
  /* static cb() {
    return "Hello!!";
  } */

  //on load complete
  init = (cb, offset) => {
    if (this.targetElement) {
      //
      const scrollbarOptions = {
        damping: 0.06,
        thumbMinSize: 20,
        renderByPixel: true,
        alwaysShowTracks: true,
        continuousScrolling: false,
        plugins: {
          // disableScroll: { direction: 'x' },
          overscroll: { effect: "bounce", damping: 0 },
        },
      };
      //SmoothScrollbar.use(OverscrollPlugin);
      SmoothScrollbar.use(
        ModalPlugin /* ,OverscrollPlugin, HorizontalScrollPlugin */
      );
      this.scrollbar = SmoothScrollbar.init(
        this.targetElement,
        scrollbarOptions
      );

      //add scroll listener
      //this.scrollbar.addListener(onScrollListener);
      //desktop custom scroller
      /*  function onScrollListener(status) {
        //init call back
        if (offset && typeof offset === "function") {
          return offset(status.offset);
        }
      } */

      //init call back
      if (cb && typeof cb === "function") {
        return cb(this.scrollbar);
      }
    }

    return this.scrollbar;
  };

  //getScrollObject
  getScrollObject = () => {
    return this.scrollbar;
  };
}

export default customScrollbar;
