import React, { useState, useEffect, createContext, useContext } from "react";

//import scroll context
import { ScrollRigContext } from "../contexts/ScrollRigContext";

//hooks
import { detectMobileDevice } from "../hooks/useWindowSize";

//create a context instance
export const NavigationContext = createContext();

//NavigationContextProvider
const NavigationContextProvider = (props) => {
  //context destructuring
  const { scrollRigInstance } = useContext(ScrollRigContext);

  //define global states
  const [navOpen, setNavOpen] = useState(false);

  //private states
  //state needed to use as scrollbar instance after scrollbar instance has been defined
  const [scrollbar, setScrollbar] = useState(null);

  //get smooth scrollbar instance from scrollRig context
  useEffect(() => {
    //update fixed elements
    if (scrollRigInstance !== "inactive-mob") setScrollbar(scrollRigInstance);

    //cleanup
    return () => {};
  }, [scrollRigInstance]);

  //
  useEffect(() => {
    if (navOpen) {
      //
      document.documentElement.setAttribute("data-navigation", "opened");
      //
      if (scrollbar !== null)
        scrollbar.updatePluginOptions("modal", { open: true });
    } else {
      //
      document.documentElement.setAttribute("data-navigation", "closed");
      //
      if (scrollbar !== null)
        scrollbar.updatePluginOptions("modal", { open: false });
    }
    return () => {};
  }, [navOpen, scrollbar]);

  //
  const toggleNavigation = (value) => {
    //the correct way to update a state value is through its previous value ;)
    switch (value) {
      case "open":
        setNavOpen(true);
        break;
      case "close":
        setNavOpen(false);
        break;
      case "close-scroll-top":
        //close nav
        setNavOpen(false);
        //scroll to top on page change
        if (detectMobileDevice() === true) {
          window.scrollTo({ top: 0, behavior: "auto" });
        } else {
          if (scrollbar !== null) scrollbar.scrollTop = 0;
        }
        break;
      default:
    }
  };

  //render the provider
  return (
    <NavigationContext.Provider value={{ navOpen, toggleNavigation }}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
