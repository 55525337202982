import React, { createContext, useState } from "react";
export const MusicContext = createContext();

//
const MusicContextProvider = (props) => {
  //
  const maxVolume = 1;
  const minVolume = 0;
  const step = 0.1;
  const defaultVolume = 0.7;

  //create a global state
  const [musicPlaying, setMusicPlaying] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(defaultVolume);

  //update the global state method / function
  const toggleMusic = () => {
    //same as creating a toggle condition
    setMusicPlaying(!musicPlaying);
  };

  //update the global volumeLevel state method / function
  const changeVolume = (value) => {
    //the correct way to update a state value is through its previous value ;)
    switch (value) {
      case "up":
        volumeLevel < maxVolume
          ? setVolumeLevel((prevVolumeLevel) => (prevVolumeLevel += step))
          : setVolumeLevel(maxVolume);
        break;
      case "down":
        volumeLevel > minVolume
          ? setVolumeLevel((prevVolumeLevel) => (prevVolumeLevel -= step))
          : setVolumeLevel(minVolume);
        break;
      default:
    }
  };

  //
  return (
    //pass the global state and the toggle function
    <MusicContext.Provider
      value={{
        musicPlaying,
        toggleMusic,
        volumeLevel,
        maxVolume,
        minVolume,
        changeVolume,
      }}
    >
      {props.children}
    </MusicContext.Provider>
  );
};

export default MusicContextProvider;
