import { useState, useEffect } from "react";

//useBrowserSize hook
export default function useNativeScroll() {
  //
  const [nativeScrollOffset, setNativeScrollOffset] = useState({ x: 0, y: 0 });

  //
  useEffect(() => {
    //
    const handleScrollEvent = () => {
      //
      let scrollX =
        window.pageXOffset !== undefined
          ? window.pageXOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollX;

      //
      let scrollY =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollY;

      //
      setNativeScrollOffset({ x: scrollX, y: scrollY });
    };

    //
    window.addEventListener("scroll", handleScrollEvent);
    //clean up
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, []);

  //
  return nativeScrollOffset;
}
