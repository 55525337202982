import React, { useContext, useLayoutEffect, useRef } from "react";

//styles
import "./ScrollRig.css";

//import scroll context
import { ScrollRigContext } from "../../contexts/ScrollRigContext";

//rfce
function ScrollRig(props) {
  //refs
  const scrollRig = useRef(null);

  //context destructuring
  const { setScrollRigElm, scrollRigInstance } = useContext(ScrollRigContext);

  //1st render
  useLayoutEffect(() => {
    setScrollRigElm(scrollRig.current);
    //
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        scrollRigInstance === "inactive-mob"
          ? "scroll-rig disabled gpu-acceleration"
          : "scroll-rig gpu-acceleration"
      }
      ref={scrollRig}
    >
      {props.children}
    </div>
  );
}

export default ScrollRig;
