//development settings

//logo
import dboardsLogo from "../media/common/logo.svg";
import dboardsDLogo from "../media/common/logo-d.svg";

//music
import defaultMusic from "../media/audio/music.mp3";

//showreel
import showreelPoster from "../media/showreel/poster.png";
import showreelPosterMobile from "../media/showreel/poster.png";

//homepage carousel
import img1 from "../media/home/carousel/1.jpg";
import img2 from "../media/home/carousel/2.jpg";
import img3 from "../media/home/carousel/3.jpg";
import img4 from "../media/home/carousel/4.jpg";
import img5 from "../media/home/carousel/5.jpg";
import img6 from "../media/home/carousel/6.jpg";

import img1Mob from "../media/home/carousel/1-mob.jpg";
import img2Mob from "../media/home/carousel/2-mob.jpg";
import img3Mob from "../media/home/carousel/3-mob.jpg";
import img4Mob from "../media/home/carousel/4-mob.jpg";
import img5Mob from "../media/home/carousel/5-mob.jpg";
import img6Mob from "../media/home/carousel/6-mob.jpg";

//about
import parallaxLayer1 from "../media/about/parallax/layer-1.png";
import parallaxLayer2 from "../media/about/parallax/layer-2.png";
import parallaxLayer1Mob from "../media/about/parallax/layer-1-mob.png";
import parallaxLayer2Mob from "../media/about/parallax/layer-2-mob.png";
import teamImage from "../media/about/team.jpg";

//work
import plantPoster from "../media/work/poster.jpg";
//categories
import kitchen from "../media/work/works/kitchen.jpg";
import salon_entrance from "../media/work/works/salon_entrance.jpg";
import bedroom from "../media/work/works/bedroom.jpg";
import stairs from "../media/work/works/stairs.jpg";
import office from "../media/work/works/office.jpg";
import outdoor from "../media/work/works/outdoor.jpg";
import vanities from "../media/work/works/vanities.jpg";
//images
import bedroom_1 from "../media/work/works/bedroom/1.jpg";
import bedroom_2 from "../media/work/works/bedroom/2.jpg";
import bedroom_3 from "../media/work/works/bedroom/3.jpg";
import bedroom_4 from "../media/work/works/bedroom/4.jpg";
import bedroom_5 from "../media/work/works/bedroom/5.jpg";
import bedroom_6 from "../media/work/works/bedroom/6.jpg";
import bedroom_7 from "../media/work/works/bedroom/7.jpg";

import kitchen_1 from "../media/work/works/kitchen/1.jpg";
import kitchen_2 from "../media/work/works/kitchen/2.jpg";
import kitchen_3 from "../media/work/works/kitchen/3.jpg";
import kitchen_4 from "../media/work/works/kitchen/4.jpg";

import office_1 from "../media/work/works/office/1.jpg";
import office_2 from "../media/work/works/office/2.jpg";

import outdoor_1 from "../media/work/works/outdoor/1.jpg";
import outdoor_2 from "../media/work/works/outdoor/2.jpg";
import outdoor_3 from "../media/work/works/outdoor/3.jpg";

import salon_entrance_1 from "../media/work/works/salon_entrance/1.jpg";
import salon_entrance_2 from "../media/work/works/salon_entrance/2.jpg";
import salon_entrance_3 from "../media/work/works/salon_entrance/3.jpg";
import salon_entrance_4 from "../media/work/works/salon_entrance/4.jpg";
import salon_entrance_5 from "../media/work/works/salon_entrance/5.jpg";
import salon_entrance_6 from "../media/work/works/salon_entrance/6.jpg";
import salon_entrance_7 from "../media/work/works/salon_entrance/7.jpg";
import salon_entrance_8 from "../media/work/works/salon_entrance/8.jpg";
import salon_entrance_9 from "../media/work/works/salon_entrance/9.jpg";
import salon_entrance_10 from "../media/work/works/salon_entrance/10.jpg";

import stairs_1 from "../media/work/works/stairs/1.jpg";

import vanities_1 from "../media/work/works/vanities/1.jpg";
import vanities_2 from "../media/work/works/vanities/2.jpg";
import vanities_3 from "../media/work/works/vanities/3.jpg";
import vanities_4 from "../media/work/works/vanities/4.jpg";
import vanities_5 from "../media/work/works/vanities/5.jpg";

//
const config = {
  //
  logo: dboardsLogo,
  logo_D: dboardsDLogo,
  music: defaultMusic,

  //members
  membersCarousel: {
    visibleSlides: 4,
    infinite: true,
    slides: [],
  },

  //homepage carousel
  homeCarousel: {
    slides: [
      {
        imgSrc: img1,
        imgSrcMob: img1Mob,
        title: "Robert’s Salon",
        subtitle:
          "Our interior designs are work of art unfolding into your space. Design and execute your home in full at D Boards!",
        link: "/work",
      },
      {
        imgSrc: img2,
        imgSrcMob: img2Mob,
        title: "Robert’s Bar",
        subtitle:
          "We are not only limited to wood servicing our carpenters; Instead our scope of work extends to Wood design combined with Ceramic works, Gipson boards, Table tops, Outdoor pergolas, etc...",
        link: "/work",
      },
      {
        imgSrc: img3,
        imgSrcMob: img3Mob,
        title: "Yara’s Kitchen",
        subtitle:
          "We work with multiple suppliers to provide a variety of panels, flooring, and all the materials you need. We help you choose the right colors, finishes, and accessories when you design in-house.",
        link: "/work",
      },
      {
        imgSrc: img5,
        imgSrcMob: img5Mob,
        title: "Rita’s Kitchen",
        subtitle:
          "At D Boards we fully design, execute, and install your envisioned projects either using processed wood panels or solid wood works based on your preferences and budget.",
        link: "/work",
      },
      {
        imgSrc: img4,
        imgSrcMob: img4Mob,
        title: "Richard’s dining",
        subtitle:
          "Design your project at D Boards and benefit from a variety of contracting services served to your doors.",
        link: "/work",
      },
      {
        imgSrc: img6,
        imgSrcMob: img6Mob,
        title: "Eliane’s Bedroom",
        subtitle:
          "Your dreams would be sweeter where you lay down your own patterns. Be magical!",
        link: "/work",
      },
    ],
  },

  //about parallax
  aboutParallax: {
    layer1: parallaxLayer1,
    layer2: parallaxLayer2,
    layer1Mob: parallaxLayer1Mob,
    layer2Mob: parallaxLayer2Mob,
  },

  aboutTeam: {
    poster: teamImage,
    posterMobile: teamImage,
  },

  //showreel
  showreel: {
    poster: showreelPoster,
    posterMobile: showreelPosterMobile,
    url: "https://www.youtube.com/watch?v=NVsW1gV2Zis",
  },

  //work
  work: {
    plant: {
      poster: plantPoster,
      posterMobile: plantPoster,
    },
    data: [
      {
        thumb: kitchen,
        images: [kitchen_1, kitchen_2, kitchen_3, kitchen_4],
        height: 1024,
        title: "KITCHEN",
        url: "kitchen",
        description: [
          "Mix of matt and standard finishes",
          "Combination of classic MFC colors",
          "Hidden beam cladding",
        ],
      },
      {
        thumb: salon_entrance,
        images: [
          salon_entrance_1,
          salon_entrance_2,
          salon_entrance_3,
          salon_entrance_4,
          salon_entrance_5,
          salon_entrance_6,
          salon_entrance_7,
          salon_entrance_8,
          salon_entrance_9,
          salon_entrance_10,
        ],
        height: 460,
        title: "SALON & ENTRANCE",
        url: "salon&entrance",
        description: [
          "Continuous elevation cladding",
          "Combination of walnut wood with ceramic",
          "Gold stainless steel stripes",
        ],
      },
      {
        thumb: bedroom,
        images: [
          bedroom_1,
          bedroom_2,
          bedroom_3,
          bedroom_4,
          bedroom_5,
          bedroom_6,
          bedroom_7,
        ],
        height: 600,
        title: "BEDROOM",
        url: "bedroom",
        description: [
          "Continuous headboard",
          "Grooving stripes in MDF",
          "Combination of white and natural wood colors",
        ],
      },
      {
        thumb: stairs,
        images: [stairs_1],
        height: 812,
        title: "STAIRS",
        url: "stairs",
        description: [
          "Stairs steel structure",
          "Stairs solid wood steps",
          "Finishing edges",
          "MDF veneer complete",
        ],
      },
      {
        thumb: office,
        images: [office_1, office_2],
        height: 480,
        title: "OFFICE",
        url: "office",
        description: [
          "Multi-Function Desk with Console and Coffee Table",
          "Glossy white combination with standard brown finishes",
          "Flooring parquet",
          "OSB Wall and Ceiling Structures",
        ],
      },
      {
        thumb: outdoor,
        images: [outdoor_1, outdoor_2, outdoor_3],
        height: 460,
        title: "OUTDOOR",
        url: "outdoor",
        description: [
          "description 1",
          "description 2",
          "description 3",
          "description 4",
        ],
      },
      {
        thumb: vanities,
        images: [vanities_1, vanities_2, vanities_3, vanities_4, vanities_5],
        height: 460,
        title: "VANITIES",
        url: "vanities",
        description: [
          "Glossy gray vanities and thick wood-top",
          "Combination of MFC and OSB wall structure",
          "Egger Honey-comb door",
        ],
      },
    ],
  },
};

export default config;
