import React, { createContext, useState, useEffect } from "react";

//hooks
import useNativeScroll from "../hooks/useNativeScroll";

//classes
import customScrollbar from "../classes/customScrollbar";
//custom hooks
import { detectMobileDevice } from "../hooks/useWindowSize";

//export ScrollRigContext
export const ScrollRigContext = createContext();
//
const ScrollRigContextProvider = (props) => {
  //
  let nativeScrollOffset = useNativeScroll();

  //create a global state
  const [scrollRigElm, setScrollRigElm] = useState(null);
  const [scrollRigInstance, setScrollRigInstance] = useState(null);
  const [scrollRigOffset, setScrollRigOffset] = useState(0);

  //
  useEffect(() => {
    //console.log(scrollRigElm);
    if (detectMobileDevice() === true) {
      setScrollRigInstance("inactive-mob");
      document.documentElement.setAttribute("data-scroll", "native");
    } else {
      //
      if (scrollRigElm) {
        //if scroll element is not null
        new customScrollbar(
          scrollRigElm,
          (scrollObject) => {
            setScrollRigInstance(scrollObject);
            document.documentElement.setAttribute("data-scroll", "scrollRig");
          },
          (offset) => {
            //in case we need to export offset from class directly as callback fn
            //console.log(offset);
          }
        );
      }
    }
    return () => {};
  }, [scrollRigElm]);

  //
  useEffect(() => {
    if (scrollRigInstance) {
      scrollRigInstance === "inactive-mob"
        ? setScrollRigOffset(nativeScrollOffset)
        : scrollRigInstance.addListener(onScrollListener);
    }
    return () => {};
  }, [scrollRigInstance, nativeScrollOffset]);

  //get smooth scroll offset if scroll instance is active on desktop
  const onScrollListener = (status) => setScrollRigOffset(status.offset);

  //
  return (
    //pass the global state and the toggle function
    <ScrollRigContext.Provider
      value={{
        setScrollRigElm,
        scrollRigInstance,
        setScrollRigInstance,
        scrollRigOffset,
      }}
    >
      {props.children}
    </ScrollRigContext.Provider>
  );
};

export default ScrollRigContextProvider;
