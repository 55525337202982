import React from "react";
import "./Preloader.css";

function Preloader(props) {
  //console.log(props);
  let classes = "preloader " + props.status;
  return (
    <div className={classes}>
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20"></circle>
      </svg>
    </div>
  );
}

export default Preloader;
