import React, { useState, createContext } from "react";

//create a context instance
export const FullscreenContext = createContext();

//
const FullscreenContextProvider = (props) => {
  //define global state
  const [fullscreen, setFullScreen] = useState(false);

  //fullscreen event handler
  document.addEventListener(
    "fullscreenchange",
    function () {
      //
      const doc = window.document;
      //
      if (
        doc.fullscreenElement &&
        doc.mozFullScreenElement &&
        doc.webkitFullscreenElement &&
        doc.msFullscreenElement
      ) {
        // fullscreen is activated
        //console.log("fullscreen is activated");
        setFullScreen(true);
      }
    },
    false
  );

  //catch f11
  window.onresize = function (event) {
    //
    let maxHeight = window.screen.height,
      maxWidth = window.screen.width,
      curHeight = window.innerHeight,
      curWidth = window.innerWidth;

    if (maxWidth === curWidth && maxHeight === curHeight) {
      // fullscreen is activated
      //console.log("fullscreen is activated");
      setFullScreen(true);
    } else {
      // fullscreen is activated
      //console.log("fullscreen is cancelled");
      setFullScreen(false);
    }
  };

  //toggle fullscreen
  function toggleFullScreen() {
    //
    const doc = window.document;
    const docEl = doc.documentElement;

    //
    let requestFullScreen =
      //
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    let cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  //render the provider
  return (
    <FullscreenContext.Provider value={{ toggleFullScreen, fullscreen }}>
      {props.children}
    </FullscreenContext.Provider>
  );
};

export default FullscreenContextProvider;
