//
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import config from "./config/config";
//global css
import "./styles/config.css";
import "./styles/reset.css";
import "./styles/normalize.css";
import "./styles/typography.css";
import "./styles/transitions.css";
import "./styles/classes.css";
import "./styles/screens.css";
import "./styles/forms.css";
import "./styles/templates.css";
import "./styles/index.css";

//
ReactDOM.render(
  <React.StrictMode>
    <>
      <App />
      <div className="unsupported-screens">
        <img className="img-responsive" alt="D BOARDS" src={config.logo} />
      </div>
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
